import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { ArticleComponent } from './components/article/article.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { ErrorComponent } from './components/error/error.component';
import { HomeComponent } from './components/home/home.component';
import { ArticleType } from './core/models/articleType.enum';

const routes: Routes = [
  {
    path: '', component: HomeComponent, children: [
      {
        path: '404', component: ErrorComponent
      },
      {
        path: 'me', component: ArticleComponent, data : {me: true}
      },
      {
        path: 'article/:id', component: ArticleComponent
      },
      {
        path: 'projects', component: CardListComponent, data: {type: ArticleType.PROJECT}
      },
      {
        path: 'skills', component: CardListComponent, data: {type: ArticleType.SKILL}
      },
      {
        path: '', component: CardListComponent
      },
    ]
  },
  {
    path: '**', redirectTo: ''
  }
];

const extraOptions: ExtraOptions = {
  anchorScrolling: 'enabled'
}

@NgModule({
  imports: [RouterModule.forRoot(routes, extraOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
