<div id="card-list-container">
  <div *ngFor="let article of articles" class="card">
    <img [src]="article.pictureUrl ? article.pictureUrl : 'https://picsum.photos/768'" [alt]="article.title + ' image'">

    <div class="card-body">
      <h4 class="card-title">
        {{article.title}}
        <span *ngIf="article.type === articleTypes.PROJECT" class="badge success">Projet</span>
        <span *ngIf="article.type === articleTypes.SKILL" class="badge secondary">Compétence</span>
      </h4>
      <h5 class="card-subtitle">{{article.subTitle}}</h5>
      <p [innerHTML]="article.content | markdown | safeHtml" class="card-text">
      </p>
      <a [routerLink]="['/article/' + article.id]" class="paper-btn">Lire</a>
    </div>
  </div>
</div>
