import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Article } from '../models/article.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ArticleType } from '../models/articleType.enum';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  private url: string;
  private articles$: BehaviorSubject<Array<Article>>;
  private meArticle: Article;
  private intro: string;

  public articles: Observable<Array<Article>>;

  constructor(private httpClient: HttpClient) {
    this.url = 'https://gist.githubusercontent.com/kqwuo/a033f1d005b84459dddb9b9c0092ade6/raw/articles.json';
    this.articles$ = new BehaviorSubject<Array<Article>>([]);
    this.articles = this.articles$.asObservable();
  }

  getArticle(id: number): Article | null {
    const result = this.articles$.value.find( article => article.id === id);
    return result ? result : null;
  }

  getMe(): Article {
    return this.meArticle;
  }

  getIntro(): string {
    return this.intro;
  }

  getCards(type: ArticleType | undefined): Array<Article> {
    let res;

    switch (type) {
      case ArticleType.PROJECT:
      case ArticleType.SKILL:
        res = this.articles$.value.filter( a => a.type === type)
        break;
      case undefined:
      default:
        res = this.articles$.value;
        break;
    }
    return res;
  }

  private getArticles(): Observable<Array<Article>> {
    return this.httpClient.get<Array<Article>>(this.url);
  }

  async initialize() {
    const articles = await this.getArticles().toPromise();
    this.meArticle = articles.find( (article, idx) => {
      if (article.id == 14) {
        articles.splice(idx, 1);
        return true;
      }
      return false;
    });
    this.intro = articles.find( (article, idx) => {
      if (article.id == 0) {
        articles.splice(idx, 1);
        return true;
      }
      return false;
    })?.content;

    this.articles$.next( articles );
  }
}
