import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less']
})
export class NavbarComponent implements OnInit {
  private _htmlElement: HTMLHtmlElement;
  public isDark: boolean;

  constructor() {
    this.isDark = false;
  }

  ngOnInit(): void {
    this._htmlElement = document.getElementsByTagName('html')[0];
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.switchNightMode();
    }
  }

  public switchNightMode(): void {
    if (!this.isDark) {
      this._htmlElement.className = "dark";
    } else {
      this._htmlElement.className = "";
    }
    this.isDark = !this.isDark;
  }
}
