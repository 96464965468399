import { ViewportScroller } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Article } from 'src/app/core/models/article.model';
import { ArticleService } from 'src/app/core/services/article.service';

@Component({
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.less']
})
export class ArticleComponent implements OnInit, AfterViewInit {
  
  public article: Article;
  public contactEmail: string;
  public contactMessage: string;
  public mailIsValid: boolean;
  public messageIsValid: boolean;
  public disableSendMail: boolean;
  public mailSuccess: boolean | undefined;

  constructor(
      private _route: ActivatedRoute,
      private _router: Router,
      private _articleService: ArticleService,
      private _viewPortScroller: ViewportScroller,
      private _httpClient: HttpClient
    ) {
      this.mailIsValid = false;
      this.messageIsValid = false;
      this.disableSendMail = true;
    }

  async ngOnInit(): Promise<void> {
    var article: Article;

    if (this._route.snapshot.data.me) {
      article = this._articleService.getMe();
    }
    else {
      const id: number = +this._route.snapshot.paramMap.get('id');

      if (id)
        article = this._articleService.getArticle(id);
      else
        article = this._articleService.getMe();
    }

    if (article)
      this.article = article;
    else
      await this._router.navigate(['404']);

    if (this._route.snapshot.fragment.length === 0) {
      window.scrollTo( {top: 0, left: 0} );
    } else {
      console.debug('oui')
      this._viewPortScroller.scrollToAnchor('contact-form');
    }
  }

  ngAfterViewInit(): void {
    if (this._route.snapshot.fragment.length > 0) {
      this._viewPortScroller.scrollToAnchor('contact-form');
    }
  }

  async sendMail(): Promise<void> {
    const content: string = JSON.stringify({
      email: this.contactEmail,
      message: this.contactMessage,
    });
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    try {
      const result = await this._httpClient.post<any>("https://app.99inbound.com/api/e/IpjVotv7", content, {headers}).toPromise();
      this.mailIsValid = true;
    } catch {
      this.mailIsValid = false;
    }
  }

  isEmailValid(): void {
    const regExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    this.mailIsValid = this.contactEmail.match(regExp) ? true : false;
  }

  isMessageValid(): void {
    this.messageIsValid = this.contactMessage.length >= 50 ? true : false;
  }
}
