<nav class="border split-nav">
  <div class="nav-brand">
    <h3><a routerLink="/">Melvin DELPIERRE</a></h3>
  </div>
  <div class="right-side">
    <ul class="inline">
      <li><a routerLink="/projects">Réalisations</a></li>
      <li><a routerLink="/skills">Compétences</a></li>
      <li><a routerLink="/me">Présentation</a></li>
      <li><a routerLink="/me" fragment="contact-form">Me contacter</a></li>
    </ul>
    <a id="night-switch" class="paper-btn margin" (click)="switchNightMode()" >
      <img [src]="isDark ? '/assets/sun.png' : '/assets/moon.png'" />
    </a>
  </div>
</nav>
