import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Article } from 'src/app/core/models/article.model';
import { ArticleType } from 'src/app/core/models/articleType.enum';
import { ArticleService } from 'src/app/core/services/article.service';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.less']
})
export class CardListComponent implements OnInit {
  articles: Array<Article>;
  articleTypes = ArticleType;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    public articleCardsService: ArticleService
  ) {
    this.articles = [];
  }

  ngOnInit(): void {
    const type: ArticleType | undefined = this._route.snapshot.data.type;
    this.articles = this.articleCardsService.getCards(type);
  }

  openArticle(id: number) {
    this._router.navigate(['article', id]);
  }
}
