import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { ArticleType } from 'src/app/core/models/articleType.enum';
import { ArticleService } from 'src/app/core/services/article.service';
import { filter } from 'rxjs/operators';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {
  private _fadeInClass: string;
  private _fadeOutClass: string;
  private _toTopButton: HTMLElement;
  public showToTopButton: boolean;
  public showIntro: boolean;

  constructor(
    private _router: Router,
    public articleService: ArticleService
  ) {
    this.showIntro = _router.url.length > 1 ? false : true;
    this.showToTopButton = false;
    this._fadeInClass = 'animate__fadeInBottomRight';
    this._fadeOutClass = 'animate__fadeOutBottomRight';
  }

  ngOnInit(): void {
    this._router.events.pipe(filter(event => event instanceof RouterEvent)).subscribe( (event: RouterEvent) => {
      this.showIntro = event.url.length > 1 ? false : true;
    });
    this._toTopButton = document.getElementById('to-top');
    this._toTopButton.style.visibility = 'hidden';

    document.onscroll = () => {
      if (window.scrollY < 30) {
        this._toTopButton.classList.remove(this._fadeInClass);
        this._toTopButton.classList.add(this._fadeOutClass);
      } else {
        this._toTopButton.style.visibility = 'visible';
        this._toTopButton.classList.remove(this._fadeOutClass);
        this._toTopButton.classList.add(this._fadeInClass);
      }
    };
  }

  public scrollToTop() {
    window.scrollTo( {top: 0, left: 0} );
  }
}
