import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ArticleComponent } from './components/article/article.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { ArticleService } from './core/services/article.service';
import { HttpClientModule } from '@angular/common/http';
import { initializeApplication } from './app.initializer';
import { MarkdownPipe, SafeHtmlPipe } from './core/utils/pipes';
import { ErrorComponent } from './components/error/error.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ArticleComponent,
    NavbarComponent,
    CardListComponent,
    MarkdownPipe,
    SafeHtmlPipe,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    {
      // Initialize CRS service
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      deps: [ArticleService],
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
