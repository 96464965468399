import { Pipe, PipeTransform } from '@angular/core';
import * as marked from 'marked';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';


@Pipe({name: 'markdown'})
export class MarkdownPipe implements PipeTransform {
  transform(content: string): string {
    return marked(content);
  }
}


@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  // sanitizingOptions and hook come from https://github.com/cure53/DOMPurify/issues/340#issuecomment-670758980 
  // to "authorize" the youtube integration iframes
  sanitizingOptions = {
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
  };

  constructor(protected sanitizer: DomSanitizer) {
    DOMPurify.addHook("uponSanitizeElement", (node: any, data) => {
      if (data.tagName === "iframe") {
        const src = node.getAttribute("src") || "";
        if (!src.startsWith("https://www.youtube.com/embed/") && !src.startsWith("https://www.youtube-nocookie.com/embed/")) {
          return node.parentNode.removeChild(node);
        }
      }
   });
  }

  public transform(value: any): any {
    const sanitizedContent = DOMPurify.sanitize(value, this.sanitizingOptions);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }
}
