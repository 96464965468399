import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'portfolio';

  ngOnInit(): void {
    const doc = document.getElementsByTagName('html')[0];
    doc.style.scrollBehavior = 'smooth';
  }
}
