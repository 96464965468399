<article class="article" *ngIf="article">
  <h1 class="article-title">{{article.title}}</h1>
  <p class="article-meta">Écrit par <a routerLink="/me">{{article.author}}</a></p>
  <p class="text-lead">{{article.subTitle}}</p>
  <p [innerHTML]="article.content | markdown | safeHtml">
  </p>
  <div *ngIf="article.id === 14" id="contact-form-container">
    Ou envoyez-moi un message ici directement :
    <div id="contact-form">
      <div class="form-group">
        <label for="contact-form-email">Email</label>
        <input type="email" placeholder="votre@email.fr" id="contact-form-email" [(ngModel)]="contactEmail" (ngModelChange)="isEmailValid()">
      </div>
      <div class="form-group">
        <label for="contact-form-message">Message</label>
        <textarea id="contact-form-message" placeholder="Passez le bonjour !" [(ngModel)]="contactMessage" (ngModelChange)="isMessageValid()"></textarea>
      </div>
      <button [disabled]="!mailIsValid || !messageIsValid" (click)="sendMail()">Envoyer</button>
      <ng-container *ngIf="mailSuccess">
        <div class="alert alert-success">Message envoyé avec succès !</div>
      </ng-container>
    </div>
  </div>
</article>
