<div id="body" class="paper">
  <div id="intro-container" *ngIf="showIntro && articleService.getIntro()">
    <div id="intro" [innerHTML]="articleService.getIntro() | markdown | safeHtml">
    </div>
    <hr>
  </div>
  <router-outlet></router-outlet>
</div>

<div id="to-top" class="animate__animated">
  <a (click)="scrollToTop()" class="paper-btn margin">^</a>
</div>
