import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.less']
})
export class ErrorComponent implements OnInit {
  public errorLink: string;

  constructor() {
    this.errorLink = 'https://i.imgur.com/YzISmLp.png'
  }

  ngOnInit(): void {
  }

}
